import { useCompanyStore } from "@/store/company";
import type { Company, BillingInformation } from "@/dto/company/company";

export const useCompanyInformation = () => {
  const { company } = storeToRefs(useCompanyStore());
  const billingInformation = computed(() => company.value?.billing_information);

  const hasCompanyInformation = computed(() => {
    if (!company.value) return false;

    const requiredFields = ["name", "description", "website", "company_type", "industry"];
    return requiredFields.every(
      (field) =>
        company.value![field as keyof Company] !== null &&
        company.value![field as keyof Company] !== undefined &&
        company.value![field as keyof Company] !== ""
    );
  });

  const hasBillingInformation = computed(() => {
    if (!billingInformation.value) return false;

    const requiredFields = [
      "contact_name",
      "contact_email",
      "contact_number",
      "street_address",
      "city",
      "postal_code",
    ];

    return requiredFields.every(
      (field) =>
        billingInformation.value![field as keyof BillingInformation] !== null &&
        billingInformation.value![field as keyof BillingInformation] !== undefined &&
        billingInformation.value![field as keyof BillingInformation] !== ""
    );
  });

  return { hasCompanyInformation, hasBillingInformation };
};
